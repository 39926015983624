import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './About.css';
import './App.css';
import './Slide.css';
import aboutHero from './assets/aboutBanner.webp';
import { TbHeartHandshake } from 'react-icons/tb';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div class="pageHeadingContainer">
                    <span class="pageHeading">About us</span>
                </div>
                <div class="pageMainPhotoContainer">
                    <img class="pageMainPhoto" src={aboutHero} />
                </div>
                <div class="pageSubHeadingContainer">
                    <span class="pageSubHeading">Our promise to you</span>
                </div>
                <TbHeartHandshake id="promiseIcon" />
                <div class="pageSubSubHeadingContainer">
                    <span class="pageSubSubHeading">The Owners First Body Corporate Customer Service Charter</span>
                </div>
                <div class="slideInFromLeft">
                    <div class="pageTextContainer">
                        <span class="pageText">At Owners First, we're less about corporate, more about people. This charter is not only our promise to you that we will maintain your property's integrity to the highest standard, it's also our promise that we will always live up to our name — by putting you first.</span>
                        <span class="pageText"><b>Under the charter we promise to:</b></span>
                        <ul class="pageText">
                            <li>Visit your property at least four times a year (quarterly)</li>
                            <li>Greet you courteously and identify ourselves by name whenever you contact us</li>
                            <li>Communicate in plain language and provide information that is concise and accurate</li>
                            <li>Listen carefully and conduct ourselves professionally at all times</li>
                        </ul>
                    </div>
                </div>
                <div class="slideInFromRight">
                    <div class="pageTextContainer">
                        <span class="pageText"><b>We will endeavour to:</b></span>
                        <ul class="pageText">
                            <li>Respond to phone calls and emails within two business days</li>
                            <li>Follow up within three working days with a progress report or a resolution if your query was not resolved during initial contact</li>
                            <li>Organise your Annual General Meeting within a maximum of three months from the end of financial year (or as agreed with committee)</li>
                            <li>Arrange repairs and maintenance within two working days (immediately where required and possible)</li>
                            <li>Supply minutes of all meetings within 14 working days</li>
                            <li>Review contracts and service providers annually to ensure your OC is receiving the best service at the best possible price</li>
                            <li>Handle your debt collection issues promptly, fairly and effectively</li>
                        </ul>
                    </div>
                </div>
                <div class="slideInFromBottom">
                    <div id="contactButtonContainer">
                        <Button id="pageButton" href="mailto:admin@ownersfirst.com.au">Get in touch with us</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;