import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FiChevronRight } from 'react-icons/fi'
import './CertificateOfCurrency.css';

class CertificateOfCurrency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitButtonText: "Search",
            certificateLink: null,
            showCertificateLink: false,
            showCertificateLinkError: false
        };
    }

    async handleSubmit() {
        this.setState({showCertificateLink: false})
        this.setState({showCertificateLinkError: false})
        try {
            let id = document.getElementById("certificateOfCurrencyID").value.toUpperCase();
            try {
                const response = await axios.get(`https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/insuranceCertificates/${id}.pdf`);
                if (response.status === 200) {
                    let certificateLink = `https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/insuranceCertificates/${id}.pdf`;
                    this.setState({ certificateLink: certificateLink });
                    this.setState({ showCertificateLink: true });
                }
            } catch (error) {
                this.setState({showCertificateLinkError: true});
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getCertificate(id) {
        try {
            const response = await axios.get(`https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/insuranceCertificates/${id}.pdf`);
            return response;
        } catch (error) {
            console.error(error);
            console.log("File doesn't exist")
        }
    }

    render() {
        return (
            <div>
                <div class="pageHeadingContainer">
                    <span class="pageHeading">Get your certificate of currency</span>
                </div>
                <div class="pageHeadingUnderContainer">
                    <span class="pageHeadingUnder">Existing customer? Access your insurance Certificate of Currency here.</span>
                </div>
                <div class="pageFormContainer">
                    <div id="proposalFormContainer">
                        <Form id="proposalForm">
                            <Form.Group className="mb-3">
                                <Form.Label>Plan of subdivision number</Form.Label>
                                <Form.Control type="text" className="proposalFormInput" id="certificateOfCurrencyID" placeholder="PS1234567" />
                            </Form.Group>
                        </Form>
                        <div id="proposalSubmitButtonContainer">
                            <Button id="certificateOfCurrencySubmitButton" type="submit" onClick={() => this.handleSubmit()}>{this.state.submitButtonText}</Button>
                        </div>
                        {this.state.showCertificateLink ?
                            <div class="certificateOfCurrencyReponseContainer">
                                <a class="certificateOfCurrencyReponseText" href={this.state.certificateLink} target="_blank">View Certificate of Currency<span><FiChevronRight id="certificateOfCurrencyViewIcon"/></span></a>
                            </div> 
                            : null}
                        {this.state.showCertificateLinkError ?
                            <div class="certificateOfCurrencyReponseContainer">
                                <span class="certificateOfCurrencyReponseText">Certificate of Currency not found.</span>
                                <span class="certificateOfCurrencyReponseText">Please enter the correct number and try again.</span>
                            </div> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default CertificateOfCurrency;