import React from 'react';
import './Footer.css';
import ownersFirstLogo from './assets/owners-first-logo.svg'
import ofOLogo from './assets/owners-first-o-logo.svg';
import SCAMemberLogo from './assets/SCA-member-logo.png.webp';
import Card from 'react-bootstrap/Card';

const Footer = () => {

    return (
        <div>
            <div id="footerContainer">
                <ul class="cardContainer wrap">
                    <li>
                        <Card id="footerCard">
                            <Card.Body id="footerInfoCard">
                                <span id="footerEmergency" onClick={() => window.open('tel:1300556550')}>After Hours Emergency: <span id="footerPh">1300 556 550</span></span>
                                <div id="homeFooterLinksContainer">
                                    <a class="footerLink" href="/proposal">Request management</a>
                                    <a class="footerLink" href="/services">Services</a>
                                    <a class="footerLink" href="/portfolio">Portfolio</a>
                                    <a class="footerLink" href="/about">About Us</a>
                                    <a class="footerLink" href="/forms">Forms</a>
                                </div>
                                <img src={ownersFirstLogo} id="footerLogoBig" />
                                <span id="footerEM"><a style={{ color: "#FFF" }} href="mailto:admin@ownersfirst.com.au">admin@ownersfirst.com.au</a></span>
                                <div id="footerAddressContainer">
                                    <span class="footerAddressItem">The Kinson</span>
                                    <span class="footerAddressItem">Level 1/452 St Kilda Rd</span>
                                    <span class="footerAddressItem">Melbourne VIC 3004</span>
                                    <span class="footerAddressItem" onClick={() => window.open('tel:0399887700')}>Phone: <span id="footerPh">03 9988 7700</span></span>
                                </div>
                            </Card.Body>
                        </Card>
                    </li>
                    <li>
                        <Card id="footerCard">
                            <Card.Body>
                                <div id="oLogoContainer">
                                    <img src={ofOLogo} id="homeOfOLogo" />
                                </div>
                                <span id="footerPrivacyStatement">We handle personal information in accordance with the Owners Corporation Act 2006 and current privacy laws. <a style={{ color: "#FFF" }} href="/privacy">Read our privacy policy here.</a></span>
                                <img src={SCAMemberLogo} id="footerStrataLogo" />
                            </Card.Body>
                        </Card>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;