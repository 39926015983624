import React from 'react';
import Card from 'react-bootstrap/Card';
import { FiChevronRight } from 'react-icons/fi'
import './Forms.css';
import './App.css';
import { useNavigate } from 'react-router-dom';

const Forms = () => {

    const navigate = useNavigate();
    const handleCOCClick = () => {
        navigate('/certificateofcurrency');
    }

    const formData = [
            {
                name: "Owners Corporation Certificate Costs",
                file: "https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/forms/Owners+Corporation+Certificate+Costs+2022+-+2023.pdf"
            },
            {
                name: "Proxy Form",
                file: "https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/forms/Proxy+Form.pdf"
            },
            {
                name: "Committee Nomination Form",
                file: "https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/forms/Committee+Nomination+Form.pdf"
            },
            {
                name: "Owner Information Form",
                file: "https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/forms/Owner+Information+Form.pdf"
            },
            {
                name: "Formal Complaint Form",
                file: "https://ownersfirst-public.s3.ap-southeast-2.amazonaws.com/forms/Formal+Complaint+Form.pdf"
            }
        ];

    const renderForms= () => {
        return formData.map(({ name, file }) => {
            return (
                <li>
                    <Card id="formCard" onClick={() => window.open(file, '_blank', 'fullscreen=yes')}>
                        <span id="formCardTitle">{name}</span>
                        <div class="formCardButtonContainer">
                            <span id="formCardButton"><FiChevronRight id="formCardIcon"/></span>
                        </div>
                    </Card>
                </li>
            )
        });
    }

    return (
            <div>
                <div class="pageHeadingContainer">
                    <span class="pageHeading">Forms</span>
                </div>
                <div class="pageHeadingUnderContainer">
                    <span class="pageHeadingUnder">Here to make your life a little easier.</span>
                </div>
                <div class="pageCardContainer" id="formCardContainer">
                    <ul class="cardContainer2 wrap">
                        <li>
                            <Card id="formCard" onClick={handleCOCClick}>
                                <span id="formCardTitle">Insurance Certificate of Currency</span>
                                <div class="formCardButtonContainer">
                                    <span id="formCardButton"><FiChevronRight id="formCardIcon"/></span>
                                </div>
                            </Card>
                        </li>
                        {renderForms()}
                    </ul>
                </div>
            </div>
        );
}

export default Forms;