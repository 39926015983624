import React from 'react';
import './Privacy.css';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div class="pageHeadingContainer">
                    <span class="pageHeading">Privacy policy</span>
                </div>
                <div id="privacyBody">
                    <span class="privacySubHeader">Our privacy commitment</span>
                    <span class="privacyBodyText">The Owners First Body Corporate Strata Pty Ltd (Owners First) is committed to respecting and properly protecting the privacy of your personal information.</span>
                    <span class="privacyBodyText">In this policy, we use the terms “us,” “we” and “our” to refer to Owners First.</span>
                    <span class="privacyBodyText">Owners First has implemented practices and procedures to ensure compliance with relevant laws, regulations and industry standards including but not limited to the Australian Privacy Principles (<b>APPs</b>) as set out in the <i>Privacy Act 1988</i> (Cth) (as amended) and to appropriately deal with inquiries or complaints from individuals as to its compliance with the APPs.</span>
                    <span class="privacyBodyText">This policy describes how we protect the personal information we hold about you, in accordance with those requirements.</span>
                    <span class="privacyBodyText">The APPs do not apply to certain records relating to the employment relationship between us and our employees. In addition, certain disclosures of personal information between related bodies corporate do not have the same protection as disclosures to other persons.</span>
                    <span class="privacySubSubHeader">What is personal information?</span>
                    <span class="privacyBodyText">Personal information means any information or an opinion about an identified or reasonably identifiable individual, whether the option is true or not and whether the information or opinion is recorded in a material form or not.</span>
                    <span class="privacySubSubHeader">What information do we collect about you?</span>
                    <span class="privacyBodyText">The types of personal information we may collect includes information relevant to our relationship with you in regards to your Owners Corporation. For example, your name, address, telephone number, email address and any property information required for the purposes of managing the Owners Corporation.</span>
                    <span class="privacyBodyText">Owners First does not collect sensitive information.</span>
                    <span class="privacySubSubHeader">How do we collect your information?</span>
                    <span class="privacyBodyText">Where possible, we will collect personal information directly from you or where it is provided to us with your permission. Information is generally sought through Owners Information forms that you complete when supply your contact details or through your interaction with us via phone, mail or email communications.</span>
                    <span class="privacyBodyText">We may also collect personal information from you from third parties. If we collect personal information about you from a third party and it is unclear whether you have consented to the disclosure to us, then we will take reasonable steps to contact you to ensure that you are aware of the circumstances surrounding the collection and the purposes for which we collect your personal information.</span>
                    <span class="privacyBodyText">We may also use technology to collect tracking information gathered from use of our website. Our web servers automatically collect limited information about your computer's connection to the internet, including IP address, when you visit our website.  This requires the use of a standard feature of browser software, called a 'cookie', which assigns to each visitor a unique random number or user ID which resides on your computer. Cookies help give us an idea of which parts of our website you are visiting but does not identify you.</span>
                    <span class="privacyBodyText">To make use of certain features on our website, you may need to register and provide your personal information as part of the registration process.</span>
                    <span class="privacySubSubHeader">Why do we collect information, how do we hold information and when will we disclose information?</span>
                    <span class="privacyBodyText">The purposes for which we will generally collect and use your information include:</span>
                    <span class="privacyBodyText">
                        <ul>
                            <li>Managing the Owners Corporation in accordance with the Owners Corporation Act 2006 (Vic);</li>
                            <li>Providing information and services to you;</li>
                            <li>Performing administrative functions;</li>
                            <li>Conducting customer service satisfaction as a means of improving or developing our products and services; and</li>
                            <li>Telling you about our other products and services.</li>
                        </ul>
                    </span>
                    <span class="privacyBodyText">Where we collect your personal information for a specific purpose not outlined above we will, at the time of collection, disclose the purpose for the collection.</span>
                    <span class="privacyBodyText">Owners First does not disclose personal information to third parties, unless they are related entities or service providers, in which case they are required to comply with this privacy policy.</span>
                    <span class="privacyBodyText">We will use reasonable steps to protect your personal information from loss, misuse, interference, unauthorised access, modification or disclosure. Such steps include using information technology and processes, restricting access to your personal information to our employees and those who perform services for us who need your personal information to do what they are engaged to do, protecting hard copy documents from unauthorised access or use by storing them at secure premises, using computer and network security systems with password protection and destroying and personal information no longer required.</span>
                    <span class="privacyBodyText">Please be aware that we cannot guarantee the protection of the personal information you provide over the internet.</span>
                    <span class="privacySubSubHeader">Will you use my personal information for marketing?</span>
                    <span class="privacyBodyText">From time to time we may use your personal information to give you information about products or services we believe you might be interested in.</span>
                    <span class="privacyBodyText">We will only use such information for the purpose of marketing where we have collected the personal information from you directly, you would reasonably expect us to use or disclose the information for the purpose of marketing, there is a means through which you can request to not receive marketing communications and you have not requested that such communication cease.</span>
                    <span class="privacyBodyText">If you do not wish to receive information about our other products or services or the products and services of our related entities, then please contact us.</span>
                    <span class="privacySubSubHeader">How can I access my personal information?</span>
                    <span class="privacyBodyText">You may request access to the personal information held about you at any time. If you wish to access your personal information, then please advise us.</span>
                    <span class="privacyBodyText">We may be prevented from permitting access to personal information held about you in certain circumstances, including where:</span>
                    <span class="privacyBodyText">
                        <ul>
                            <li>Access would unreasonably impact on the privacy of another individual;</li>
                            <li>Access would lead to the disclosure of commercially sensitive information of our or any of our agents or contractors;</li>
                            <li>We are prohibited by law or a court/tribunal order; or</li>
                            <li>The information relates to existing or anticipated legal proceedings.</li>
                        </ul>
                    </span>
                    <span class="privacyBodyText">If we deny you access to your personal information, we will provide a written explanation of the reasons for such a refusal, together with details of our complaints handling procedure if you wish to challenge the decision.</span>
                    <span class="privacyBodyText">Owners First may charge you our reasonable costs incurred in supplying you with access to this information.</span>
                    <span class="privacySubSubHeader">What if my personal information is incorrect?</span>
                    <span class="privacyBodyText">Owners First will take all reasonable steps to ensure that personal information held about you is accurate, complete and up-to-date. If you believe that the personal information we hold about you is incorrect, out of date or incomplete, then please advise us.</span>
                    <span class="privacyBodyText">We will take all reasonable steps to correct the information where we are satisfied that it is inaccurate, out-of-date, incomplete, irrelevant or misleading for the purpose for which it is held.</span>
                    <span class="privacySubSubHeader">Can I remain anonymous?</span>
                    <span class="privacyBodyText">You may elect to have your anonymity protected by withholding your personal information or using a pseudonym however given the nature of the services provided by Owners First, this may not be feasible.</span>
                    <span class="privacySubSubHeader">Is my personal information disclosed to overseas recipients?</span>
                    <span class="privacyBodyText">Owners First does not disclose personal information to overseas recipients.</span>
                    <span class="privacySubSubHeader">Will I be notified if this privacy policy changes?</span>
                    <span class="privacyBodyText">This privacy policy replaces any previously issued privacy policies. Our privacy policy may change from time to time and we will notify you of any change by posting an updated version on our website at <a href="/">https://www.ownersfirst.com.au</a>.</span>
                    <span class="privacySubSubHeader">How can I lodge a complaint / contact Owners First?</span>
                    <span class="privacyBodyText">If you have a complaint regarding Owners First’s compliance with privacy laws or the treatment of your personal information, or if you want to contact us generally, then please contact us:</span>
                    <span class="privacyBodyText">
                        <ul>
                            <li>By phone on (03) 9988 7700</li>
                            <li>Via email at admin@ownersfirst.com.au</li>
                            <li>In writing at The Kinson, Level 1/452 St Kilda Rd, Melbourne, VIC, 3004</li>
                        </ul>
                    </span>
                </div>
            </div>
        );
    }
}

export default Privacy;