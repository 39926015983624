import './App.css';
import Home from './Home';
import Privacy from './Privacy';
import Services from './Services';
import About from './About';
import Forms from './Forms';
import Portfolio from './Portfolio';
import Proposal from './Proposal';
import CertificateofCurrency from './CertificateOfCurrency';
import Navigation from './Navigation';
import Footer from './Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Navigation />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/proposal" element={<Proposal />} />
          <Route path="/certificateofcurrency" element={<CertificateofCurrency />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;