import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './Portfolio.css';
import './App.css';
import buildingExample from './assets/buildExample.jpg';
import building557 from './assets/building557.jpg';
import building835 from './assets/building835.jpg';
import buildingOneCQ from './assets/buildingOneCQ.jpg';
import building33M from './assets/building33M.jpg';
import buildingPentridge from './assets/buildingPentridge.jpg';
import buildingVictoriaBurke from './assets/buildingVictoriaBurke.jpg';
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
    const navigate = useNavigate();
    const handleRequestManagementClick = () => {
        navigate('/proposal');
    }

    useEffect(() => {
        // Scroll to the top when component mounts
        window.scrollTo(0, 0);
    }, []);

    const propertyCardData = [
        {
            name: "33M",
            address: "33 MacKenzie St, Melbourne",
            photo: building33M,
            description: "Built in 2012, 33M is a 31-story residential tower with over 390 apartments. Featuring a contemporary facade, ground-floor retail, and rooftop communal facilities, it offers high-quality living in Melbourne's east-end CBD."
        },
        {
            name: "Metro Park West",
            address: "557 Little Lonsdale St, Melbourne",
            photo: building557,
            description: "Completed in 2014, 557 Little Lonsdale St, also known as Metro Park West, is a sleek 26-story building in Melbourne. This modern development integrates 195 apartments and a retail outlet. With a contemporary design, it adds a stylish touch to the urban landscape, offering a blend of residential and commercial spaces in the heart of the city."
        },
        {
            name: "835",
            address: "835 High St, Armadale",
            photo: building835,
            description: "A masterpiece of luxury that embodies the art of fine living. Boasting 26 spacious apartments, each exquisitely crafted with the finest materials and finishes, provides a seamless blend of luxury and lifestyle in the vibrant suburb of Armadale."
        },
        {
            name: "One Coburg Quarter",
            address: "11 Urquhart St, Coburg",
            photo: buildingOneCQ,
            description: "Inspired by the unique character of Coburg and historic precincts in New York City and London, One Coburg Quarter connects Melbourne's northern hub to the past and future."
        },
        {
            name: "Victoria & Burke",
            address: "163 Victoria Rd, Camberwell",
            photo: buildingVictoriaBurke,
            description: "Nestled in a prominent location, Victoria & Burke is a garden paradise that echoes the unique language and character of Camberwell with its modern interpretation of a traditional style."
        },
        {
            name: "Pentridge Prison",
            address: "Coburg",
            photo: buildingPentridge,
            description: "Once a home to Australia's most notorious criminals, Pentridge Prison has transformed into a staged development that will feature over 3,000 apartments, as well as residential and commercial spaces, right in the heart of Coburg."
        }
    ];

    const renderProperties = () => {
        return propertyCardData.map(({ name, address, photo, description }) => {
            return (
                <li>
                    <div class="flip-card-container">
                        <div class="flip-card">
                            <div class="card-front">
                                <Card id="portfolioCard">
                                    <Card.Img variant="top" src={photo} id="portfolioCardImage" />
                                    <Card.Title id="portfolioCardTitle">{name}</Card.Title>
                                    <Card.Body id="portfolioCardBody">
                                        <Card.Text id="portfolioCardBlurb">{address}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div class="card-back">
                                <Card id="portfolioCard">
                                    <Card.Img variant="top" src={photo} id="portfolioCardBackImage" />
                                    <Card.Text id="portfolioCardBackText">{description}</Card.Text>
                                </Card>
                            </div>
                        </div>
                    </div>
                </li>
            )
        });
    }

    return (
        <div>
            <div class="pageHeadingContainer">
                <span class="pageHeading">Experience the difference</span>
            </div>
            <div class="pageHeadingUnderContainer">
                <span class="pageHeadingUnder">Discover some of the properties where we have transformed and delivered a greater Owners Corporation experience.</span>
            </div>
            <div class="pageCardContainer">
                <ul class="cardContainer2 wrap">
                    {renderProperties()}
                </ul>
            </div>
            <Button id="pageButton" onClick={handleRequestManagementClick}>Request management</Button>
        </div>
    );
}

export default Portfolio;